<template>
  <b-row class="border-bottom-row my-2 py-2">
    <b-col cols=12 sm=6>
      <b-link class="align-middle" @click="showCookies = !showCookies">{{category.title}} ({{category.cookies.length}})</b-link>
    </b-col>
    <b-col cols=12 sm=6 class="text-left text-sm-right">
      <b class="text-success mr-2" v-if="category.required">Sempre ativo</b>
      <b-form-checkbox
            :checked="value"
            @change="$emit('input', $event)"
            class="d-inline-block mr-2"
            v-else
          >
            {{ selectText }}
        </b-form-checkbox>
      <b-icon icon="chevron-down" @click="showCookies = !showCookies"/>
    </b-col>
    <b-col class="text-right my-2" v-if="!category.required && !value">
      <span class="bg-warning text-dark p-1"><b-icon-exclamation-triangle-fill /> Se você não permitir esses cookies, esses serviços podem não funcionar corretamente.</span>
    </b-col>
    <b-col cols=12 >
      <p class="my-2">{{ category.description }}</p>
    </b-col>
    <b-col v-if="showCookies">
      <b-row>
        <b-col cols=4 v-for="(cookie, index) in category.cookies" :key="index">
          <b-row>
            <b-col>
              <b>Cookies</b>
            </b-col>
            <b-col>
              {{ cookie.name }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b>Vencimento</b>
            </b-col>
            <b-col>
              {{ cookie.expiration }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b>Domínio</b>
            </b-col>
            <b-col>
              {{ cookie.domain }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b>Empresa</b>
            </b-col>
            <b-col>
              {{ cookie.company }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b>Finalidade</b>
            </b-col>
            <b-col>
              {{ cookie.reason }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ cookie.description }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'AppCookiePolicyCategory',
  props: {
    category: {
      type: Object,
      default: () => ({ title: '', description: '', cookies: [], return: false })
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showCookies: false,
    select: true,
    consent: localStorage.getItem('cookieConsent') ? JSON.parse(localStorage.getItem('cookieConsent')) : null
  }),
  computed: {
    selectText () {
      return this.select ? 'Desselecionar toda classe' : 'Selecionar toda classe'
    }
  }
}
</script>
<style scoped>
a {
  color: #8694b2!important;
}
</style>
